@font-face {
  font-family: "Lota Grotesque";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Los Andes  Lota Grotesque Regular.woff")
  format("woff");
}

@font-face {
  font-family: "Lota Grotesque";
  font-style: normal;
  font-weight: 600;
  src: local("Lota Grotesque Semi Bold"),
    url("../assets/fonts/Los Andes  Lota Grotesque Semi Bold.woff") format("woff");
}

@font-face {
  font-family: "Lota Grotesque";
  font-style: normal;
  font-weight: 700;
  src: local("Lota Grotesque Bold"),
    url("../assets/fonts/Los Andes  Lota Grotesque Bold.woff") format("woff");
}

@font-face {
  font-family: "Lota Grotesque";
  font-style: normal;
  font-weight: 900;
  src: local("Lota Grotesque Black"),
    url("../assets/fonts/Los Andes  Lota Grotesque Black.woff") format("woff");
}

@font-face {
  font-family: 'ITC Souvenir';
  src: url('../assets/fonts/Souvenir-Demi.woff2') format('woff2'),
  url('../assets/fonts/Souvenir-Demi.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Souvenir';
  src: url('../assets/fonts/Souvenir-Bold.woff2') format('woff2'),
  url('../assets/fonts/Souvenir-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Souvenir';
  src: url('../assets/fonts/Souvenir-Medium.woff2') format('woff2'),
  url('../assets/fonts/Souvenir-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

